<template>
    <div class="flex items-center">
        <MascotIcon class="h-35 w-35 lg:h-[45px] lg:w-[45px]"/>
        <div class="text-10 md:text-12 lg:text-14 text-save font-semibold">
            {{ $translate('Client.ProductDetailsPage.EarnBonus') }}
            <div class="whitespace-nowrap text-center">
                {{ formatPrice(bonus) }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import MascotIcon from '@/project/components/mascot/MascotIcon.vue';
import { useBasket } from '@/project/apis/commerce/basketApi';
import { formatPrice } from '@/project/products/priceFormatter';

const { bonus } = useBasket();

</script>
