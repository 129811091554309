<template>
    <div>
        <div class="pt-15 sm:pl-30 px-30 flex items-baseline justify-start pb-10 sm:pr-0 xl:justify-between" :class="{'border-b border-lightBorder': popular.length > 0}">
            <h4 class="text-20 font-bold">
                {{ $translate('Client.ProductFilters.Title') }}
            </h4>
            <Button
                v-if="appliedFilters > 0"
                class="md:h-20 xl:-mr-8"
                :label="$translate('Client.ProductFilters.Reset')"
                transparent
                @click="clearFilters"/>
        </div>
        <div class="flex justify-center">
            <div class="sm:w-410 w-full">
                <div v-if="bonus || popular.length > 0" class="pl-30 bg-white pb-10 pr-20 pt-20">
                    <div class="mb-15 text-14 font-semibold">
                        {{ $translate('Client.ProductFilters.Popular') }}
                    </div>
                    <div v-if="bonus" class="mb-10 flex cursor-pointer items-center justify-between">
                        <div
                            class="flex items-center"
                            @click="emit('toggleBonus')">
                            <InputCheckbox :checked="bonus.isActive" :name="bonus.value" class="ml-4 mr-10 mt-4"/>
                            <CIcon
                                class="text-bonus-background"
                                name="bonus"
                                width="23"
                                height="23"
                                original/>
                            <span class="ml-10">
                                {{ $translate(`Client.Markings.Bonus`) }}
                            </span>
                        </div>
                        <div class="text-fadedText pr-8 text-center">
                            {{ bonus.count }}
                        </div>
                    </div>
                    <div
                        v-for="item in popular"
                        :key="item.value.value"
                        class="mb-10 flex cursor-pointer items-center justify-between"
                        @click="toggleFacetValue(item.facet, item.value)">
                        <fieldset class="flex items-center">
                            <InputCheckbox :id="item.value.value" :checked="item.value.isActive" :name="item.value.value" class="ml-4 mr-10 mt-4"/>
                            <CIcon
                                v-if="item.icon"
                                :name="item.icon"
                                width="23"
                                height="23"
                                :translate="item.translate"
                                original/>
                            <label class="ml-10" :for="item.value.value">
                                {{ item.translate ? $translate(`Client.Markings.${item.value.value}`) : item.value.value }}
                            </label>
                        </fieldset>
                        <div class="text-fadedText pr-8 text-center">
                            {{ item.value.count }}
                        </div>
                    </div>
                </div>
                <Accordion
                    v-for="facet in model"
                    :key="facet.key"
                    :title="$translate(`Client.ProductFilters.${facet.displayName}`)"
                    compact
                    class="bg-white"
                    :default-open="facet.isActive">
                    <div class="max-h-2000 overflow-y-auto">
                        <div
                            v-for="item in facet.values"
                            :key="item.value"
                            class="group mb-10 flex cursor-pointer items-center justify-between"
                            @click="toggleFacetValue(facet, item)">
                            <fieldset class="flex items-center">
                                <InputCheckbox :id="`${facet.key}${item.value}`" :checked="item.isActive" :name="item.value" class="group-hover:border-primary ml-4 mr-10 mt-4"/>
                                <CIcon
                                    v-if="facet.key === 'marking'"
                                    :name="item.value"
                                    width="20"
                                    height="20"
                                    translate
                                    original/>
                                <label class="ml-10" :for="`${facet.key}${item.value}`">{{ facet.key === 'marking' ? $translate(`Client.Markings.${item.value}`) : item.value }}</label>
                            </fieldset>
                            <div class="text-fadedText pr-8 text-center">
                                {{ item.count }}
                            </div>
                        </div>
                    </div>
                </Accordion>
                <div class="h-5 rounded-b-lg bg-white"></div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Accordion from '@/project/components/accordion/Accordion.vue';
import { useFacets } from '@/core/browser/query/useQueryParams';
import Button from '@/project/components/button/Button.vue';
import { FacetValueViewModel, FacetViewModel } from '@/api/commerce';
import InputCheckbox from '@/project/forms/InputCheckbox.vue';
import { trackFacetUse } from '@/project/tracking/tracking.service';

interface PopularFilter {
    icon: string;
    translate: boolean;
    facet: FacetViewModel;
    value: FacetValueViewModel;
}

const props = defineProps<{
    model: FacetViewModel[],
    bonus: FacetValueViewModel | undefined
}>();

const emit = defineEmits(['resetFilters', 'toggleBonus']);

const {
    applyFacets,
    setFacetValues,
    facets,
} = useFacets();

function clearFilters() {
    applyFacets({}, true);
    emit('resetFilters');
}

function toggleFacetValue(facet: FacetViewModel, value: FacetValueViewModel) {
    value.isActive = !value.isActive; // Fast local change before we get BE response.
    setFacetValues(facet.key, facet.values.filter(f => f.isActive).map(f => f.value));

    trackFacetUse(value.isActive ? 'Add' : 'Remove', facets.value, facet, value);
}

const appliedFilters = computed(() => props.model.filter(x => x.isActive).length);

// Collect isPopular values and add icon if relevant
const popular = computed<PopularFilter[]>(() => {
    const _popular: PopularFilter[] = [];
    for (const facet of props.model) {
        for (const value of facet.values) {
            if (value.isPopular) {
                const _value = { value, facet } as PopularFilter;

                if (facet.displayName.toLowerCase() === 'offers') {
                    _value.icon = 'offer';
                }
                if (facet.displayName.toLowerCase() === 'marking') {
                    _value.icon = value.value;
                    _value.translate = true;
                }
                _popular.push(_value);
            }
        }
    }

    return _popular.sort((a, b) => a.value.popularSortOrder! - b.value.popularSortOrder!);
});
</script>

<style scoped>
</style>
