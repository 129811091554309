<template>
    <div v-if="!isRemoving">
        <div v-if="model.invalidReason" class="bg-warning px-20 py-8 font-bold">
            {{ $translate(`Client.BasketStatus.${model.invalidReason}`) }}
        </div>
        <div class="grid-cols-24 relative grid bg-white px-20 py-10" :class="{'border border-warning': model.invalidReason}">
            <!-- Main info and picture -->
            <RouterLinkConditional :url="model.url" class="col-span-9 flex items-center" :disabled="!!model.invalidReason">
                <div class="md:mr-25 mr-10 h-40 w-40 shrink-0 md:h-60 md:w-60" :class="{'grayscale opacity-50': disableItem}">
                    <ResponsiveImage
                        :image-url="model.imageUrl"
                        :aspect-ratio="1/1"
                        :fixed-width-on-screen="isMobile ? 40 : 60"
                        mode="pad"/>
                </div>
                <div class="flex flex-col">
                    <div class="text-13 sm:text-14" :class="{'text-fadedText': disableItem}">
                        {{ model.displayName }}
                    </div>
                    <div class="text-fadedText text-11 md:text-14">
                        {{ productTeasers }}
                    </div>
                    <div
                        class="flex space-x-10"
                        :class="{'grayscale opacity-50': disableItem}">
                        <CIcon
                            v-for="icon in model.icons"
                            :key="icon"
                            :name="icon"
                            width="20"
                            height="20"
                            original
                            translate/>
                    </div>
                </div>
            </RouterLinkConditional>

            <div class="col-span-3 hidden items-center justify-center sm:flex">
                <span v-if="fewLeftInStock && !model.invalidReason" class="text-12 text-errorText whitespace-nowrap">{{ $translate('Client.ProductDetailsPage.FewLeft') }}</span>
            </div>

            <!-- Add/Remove/Change quantity -->
            <div class="z-1 col-span-6 flex flex-col items-center justify-center text-right sm:col-span-3">
                <div class="flex items-center justify-center text-right">
                    <Button
                        v-if="!disableItem"
                        size="small"
                        transparent
                        @click="onDecrement({delayRemoval: true})">
                        <CIcon
                            name="minus"
                            width="12"
                            height="12"
                            class="text-primary"/>
                    </Button>
                    <div class="relative">
                        <input
                            ref="inputField"
                            class="bg-background h-35 text-14 w-40 rounded border-0 text-center font-bold"
                            type="number"
                            :value="focussingField ? model.quantity : ''"
                            :disabled="disableItem"
                            @focus="focussingField = true"
                            @blur="focussingField = false"
                            @change="onChange">
                        <div
                            v-if="!focussingField"
                            class="absolute inset-0 flex cursor-text items-center justify-center"
                            @click="inputField.focus()">
                            <AnimateValue animation="scroll">
                                <div :key="model.quantity" class="font-bold" :class="{'text-fadedText': disableItem}">
                                    {{ model.quantity }}
                                </div>
                            </AnimateValue>
                        </div>
                    </div>
                    <Button
                        v-if="!disableItem"
                        :disabled="stockLimitHit || !!model.invalidReason"
                        transparent
                        @click="onIncrement">
                        <CIcon
                            name="plus"
                            width="12"
                            height="12"
                            class="text-primary"/>
                    </Button>
                </div>
                <div v-if="fewLeftInStock && !model.invalidReason">
                    <span class="text-12 text-errorText flex items-center justify-center whitespace-nowrap sm:hidden">{{ $translate('Client.ProductDetailsPage.FewLeft') }}</span>
                </div>
            </div>

            <!-- Pricing details (extra info for larger screens) -->
            <div
                v-if="!isMobile"
                class="col-span-3 flex flex-col justify-end text-right"
                :class="{'justify-center': !model.pricePerUnit && !model.unitOfMeasure}">
                <div :class="{'text-fadedText': disableItem}">
                    {{ formatPrice(model.discountedPrice || model.salesPrice) }}
                </div>
                <div
                    v-if="model.pricePerUnit && model.unitOfMeasure"
                    class="text-fadedText text-12 lg:text-11 xl:text-14 mb-10">
                    {{ formatPrice(model.pricePerUnit) }} / {{ model.unitOfMeasure }}
                </div>
            </div>

            <!-- Pricing details -->
            <div
                class="relative col-span-6 flex flex-col items-end justify-end text-right sm:col-span-4"
                :class="{'justify-center': !model.discountedPrice, 'text-fadedText': disableItem}">
                <!-- Discount amount - what have I saved -->
                <div
                    v-if="model.totalDiscountedPrice"
                    class="text-11 md:text-12 xl:text-14 bg-splash mb-4 whitespace-nowrap rounded px-4 pt-2 text-black"
                    :class="{'text-fadedText': disableItem}">
                    {{ $translate('Client.ProductDetailsPage.Save') }} {{ formatPrice(model.totalDiscountAmount) }}
                </div>

                <!-- Total price (with discounts) -->
                <div class="text-13 md:text-14 font-bold">
                    {{ formatPrice(model.totalDiscountedPrice || model.totalSalesPrice) }}
                </div>

                <!-- Price per unit - xx/kg -->
                <div
                    v-if="model.pricePerUnit && model.unitOfMeasure && isMobile"
                    class="text-fadedText text-11 md:text-12 lg:text-11 xl:text-14 mb-10">
                    {{ formatPrice(model.pricePerUnit) }} / {{ model.unitOfMeasure }}
                </div>

                <!-- Campaign bonus -->
                <div v-if="model.storeCampaign" class="text-12 text-campaign mt-4 w-full max-w-[80%] leading-[12px]">
                    {{ $translate('Client.ProductDetailsPage.EarnBonus') }} <span class="whitespace-nowrap">{{ formatPrice(model.storeCampaign?.bonusAmount) }}</span>
                </div>
            </div>
            <div class="col-span-2 mr-[-1.5rem] flex items-center justify-center">
                <div :class="{'bg-warning rounded-full': !!model.invalidReason}">
                    <Button class="cursor-pointer" transparent @click="deleteItem">
                        <CIcon
                            name="delete"
                            width="20"
                            height="20"
                            class="text-removeLineItem opacity-80"/>
                    </Button>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <LineItemRemoveState :display-name="model.displayName" :image-url="model.imageUrl" :size="isMobile ? 'sm' : 'lg'" @undo="stopRemoval"/>
    </div>
</template>

<script setup lang="ts">
import { LineItemInvalidReasonViewModel, LineItemViewModel } from '@/api/commerce';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import { computed, DeepReadonly, ref } from 'vue';
import Button from '@/project/components/button/Button.vue';
import { useLineItem } from '@/project/apis/commerce/basketApi';
import { parseQuantity } from '@/project/products/quantityParser';
import AnimateValue from '@/project/animation/AnimateValue.vue';
import { formatPrice } from '@/project/products/priceFormatter';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import RouterLinkConditional from '@/core/navigation/RouterLinkConditional.vue';
import LineItemRemoveState from '@/project/basket/basket-line-item/LineItemRemoveState.vue';
import { trackAddToBasket } from '@/project/tracking/tracking.service';
import { useStock } from '@/project/products/useStock';

const props = defineProps<{
    model: DeepReadonly<LineItemViewModel>;
    position?: number;
}>();

const inputField = ref();
const focussingField = ref(false);

const { smaller } = useBreakpoints();
const isMobile = smaller('sm');

const productTeasers = [
    props.model.brand,
    props.model.weight,
    props.model.productTeaser,
].filter(elem => elem).join(', ');

const { decrement, increment, manualChange, isRemoving, stopRemoval, lineItem } = useLineItem(props.model.sku);

const { fewLeftInStock, stockLimitHit } = useStock(props.model as LineItemViewModel, lineItem);

const disableItem = computed(() => props.model.invalidReason === LineItemInvalidReasonViewModel.ProductNotFound || (!props.model.alwaysInStock && props.model.availableInStock <= 0));

let prevQuantity = lineItem.value?.quantity ?? 0;
function onIncrement() {
    increment();
    trackAddToBasket({
        increasedQuantity: true,
        quantity: 1,
        product: lineItem.value!,
        lineItemMode: true,
        list: { listType: 'Basket', choosingMechanism: 'User' },
        index: props.position,
    });
    prevQuantity = prevQuantity + 1;
}

function onDecrement(options: {delayRemoval: boolean}) {
    decrement(options);
    trackAddToBasket({
        product: lineItem.value!,
        increasedQuantity: false,
        lineItemMode: true,
        quantity: 1,
        list: { listType: 'Basket', choosingMechanism: 'User' },
        index: props.position,
    });
    prevQuantity = prevQuantity - 1;
}

function onChange(e: Event) {
    const newValue = parseQuantity((e.target as HTMLInputElement).value);
    if (newValue >= 0) {
        manualChange(newValue);
    }

    const isAdding = prevQuantity < newValue;
    trackAddToBasket({
        product: lineItem.value!,
        increasedQuantity: isAdding,
        lineItemMode: true,
        quantity: isAdding ? newValue - prevQuantity : prevQuantity - newValue,
        list: { listType: 'Basket', choosingMechanism: 'User' },
        index: props.position,
    });

    prevQuantity = newValue;
}

const deleteItem = () => {
    manualChange(0);
    trackAddToBasket({
        product: lineItem.value!,
        increasedQuantity: false,
        lineItemMode: true,
        quantity: prevQuantity,
        list: { listType: 'Basket', choosingMechanism: 'User' },
        index: props.position,
    });
};
</script>
